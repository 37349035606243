import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import Seo from '../components/seo'

const PrivacyPolicy = styled.div`
  padding-top: 2%;
  padding-left: 15%;
  padding-right: 15%;
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.25em;
  }
`

class PrivacyPolicyComponent extends React.Component {
  componentDidMount() {
    const script = document.createElement('script')

    script.src = 'https://cdn.iubenda.com/iubenda.js'
    script.async = true

    document.body.appendChild(script)
  }

  render() {
    return (
      <div>
        <a
          href="https://www.iubenda.com/privacy-policy/39309978"
          className="iubenda-white no-brand iubenda-embed iub-body-embed"
          title="Privacy Policy"
        >
          Privacy Policy
        </a>
      </div>
    )
  }
}

const PrivacyPolicyPage = () => (
  <Layout>
    <Seo
      title="Privacy Policy"
      description="Our privacy policy is simple- we protect the information that you give us and do not share it with others."
      path="privacy-policy"
    />
    <PrivacyPolicy>
      <div>
        <PrivacyPolicyComponent />
      </div>
    </PrivacyPolicy>
  </Layout>
)

export default PrivacyPolicyPage
